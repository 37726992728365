var $ = require( "jquery" );
var jQuery = $;
import * as bootstrap from 'bootstrap';


const plg = require("../lib/functions-comunes");
const plg2 = require("../lib/oms-plg");

require('jquery-ui/ui/widget');
require('jquery-ui/ui/keycode');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/widgets/slider');
require('jquery-ui/themes/base/core.css');
require('jquery-ui/themes/base/slider.css');
var map;
var oms;
var markers;
var infowindow;
var geocoder;
var bounds;
var icon;
var myModal;
var myModalError;
var myModal2;
var filterused = false;
export default {
  init() {
    // JavaScript to be fired on the home page
    console.log('init offers');
    myModalError = new bootstrap.Modal(document.getElementById('modal-error'), {}); 


    $('.js-list').on('click', function(){
        $('#section_offers').show();
        $(this).addClass('active');
        $('#section_map').hide();
        $('.js-map').removeClass('active');

        $("#section_offers").removeClass("col-md-5");
				$(".js-offer").addClass("col-md-6");
				$(".js-offer").addClass("col-lg-5");


    });
    
    $('.js-map').on('click', function(){
      //$('#section_offers').hide();
      /*
      //MIXPANEL
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Map button", {
          "Click": true
        });	
      }	
      */
      $('.js-list').removeClass('active');
      			

      $('#section_map').show();
      if ($(window).width() > 767){
        plg.PLG_scrollTo(1,$('.cont-title'),0,-100);
      }
      else{
        plg.PLG_scrollTo(1,$('.cont-title'),0,0);
      }

      $("#section_offers").addClass("col-md-5");
			$(".js-offer").removeClass("col-md-6");
			$(".js-offer").removeClass("col-lg-5");	

      $(this).addClass('active');
      setTimeout(function(){
        map.setZoom(5);
      },100);
    });

    $('.js-activities').on('change', function(){
      filterused=true;
      var _activity = $(this).val();
      if (_activity == ''){
        $('.js-item-list').show();
        $('.js-item-list').removeClass('d-none');
      }else{  
        $('.js-item-list').hide();
        $('.js-item-list').addClass('d-none');
        $('.js-item-list[data-activity="'+_activity+'"]').show();
        $('.js-item-list[data-activity="'+_activity+'"]').removeClass('d-none');
      }  
      initMap();
    });

    $('.js-button-download').on('click', function(){
          showModalDownload($(this));
    });

    $('.js-close').on('click', function(){
      myModal2.hide();
    });  

    $('.js-filtrar').on('change', function(){
      filterOffers();
    });  

    var titleTop = $('.cont-title').offset().top-$("header").height();
    $(window).scroll(function(event) {
      var valorScroll=$(window).scrollTop();
      if (valorScroll > titleTop){
        $('body').addClass('mini');
      }else{
        $('body').removeClass('mini');
      }
    });
 
  },
  callback_ajax(funcion,response){
    eval("this.callback_"+funcion+"("+response+")");
  },
  callback_sectionoffers(res){
    var _resultcb = res;
    $(".bg-animated").removeClass("bg-animated");
    $(".hide-while-loading").removeClass("hide-while-loading");
    $(".show-while-loading").remove();
    if (_resultcb['result'] == 'OK'){
        jQuery.each( _resultcb['data'].activities, function( i, val ) {
          $('#selActivities').append("<option value='"+val.split(';')[1]+"' >"+val.split(';')[0]+"</option>");
          $('#selActivities').trigger("chosen:updated"); 
        }); 
      //console.log(_resultcb['regions']);
      if (_resultcb['data'].activities.length > 1){
        $('.js-activities').removeClass('d-none');
        $('.js-activities').parent('div').parent('div').removeClass('d-none');
      }else{
        $('.js-activities').addClass('d-none');
        $('.js-activities').parent('div').parent('div').addClass('d-none');
      }

      var _regions_total = 0;
      jQuery.each( _resultcb['regions'], function( i, val ) {
        if (val!=''){
          $('#selRegions').append("<option value='"+val+"' >"+i+"</option>");
          $('#selRegions').trigger("chosen:updated"); 
          _regions_total++;
        }
      }); 
      if (_regions_total > 1){
        $('.js-regions').removeClass('d-none');
      }else{
        $('.js-regions').addClass('d-none');
      }

      $('#title-subcategory').html(_resultcb['title']);
      $('#header-image').css('background-image', 'url('+_resultcb['image']+')');  
      var objName='.card-single.loadajax-item';
      $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
      var _elem = $(objName);
      
      markers = [];
      var ind = 0;

      jQuery.each( _resultcb['data'].list, function( i, val ) {
        //console.log('Address1: '+val.Address1);
        markers[ind] = '{titulo:"'+val.OfferTerm1_parse+'", centro:"'+val.VenueName_parse+'", lat:'+val.Latitude+', lng:'+val.Longitude+', num: "'+val.VenueId+'", direccion:"'+val.Address+'" ,ciudad:"'+val.City+'", provincia:"'+val.Region+'", telefono:"'+val.Phone+'", item:"item_'+val.experience_id+'", tipo:"'+val.type_inout+'" }';
        ind++;
        var tmpobj=_elem.clone(true).appendTo(_elem.parent());      
        tmpobj.find('.js-item-list').attr('id', 'item_'+val.experience_id);
        tmpobj.find('.js-item-list').attr('data-offerId', val.experience_id);
        tmpobj.find('.js-item-list').attr('data-Activity', val.activity_parse);
        tmpobj.find('.js-item-list').attr('data-Latitude', val.Latitude);
        tmpobj.find('.js-item-list').attr('data-Longitude', val.Longitude);
        tmpobj.find('.js-item-list').attr('data-VenueId', val.VenueId);
        tmpobj.find('.js-item-list').attr('data-RewardId', val.RewardId);
        tmpobj.find('.js-item-list').attr('data-RewardAlias', val.RewardAlias_title);
        tmpobj.find('.js-item-list').attr('data-City', val.City);
        tmpobj.find('.js-item-list').attr('data-HeadOfChainId', val.HeadOfChainId);
        tmpobj.find('.js-item-list').attr('data-Region', val.Region);
        tmpobj.find('.js-item-list').attr('data-RegionSearch', val.Region_parse);        
        tmpobj.find('.js-item-list').attr('data-Phone', val.Phone);
        tmpobj.find('.js-item-list').attr('data-Address', val.Address);
        tmpobj.find('.js-item-list').attr('data-RewardName', val.RewardName);
        tmpobj.find('.js-item-list').attr('data-VenueName', val.VenueName);
        tmpobj.find('.js-item-list').attr('data-Category', val.Categoria);
        tmpobj.find('.js-item-list').attr('data-Subcategorytxt', val.RewardAlias_title);
        tmpobj.find('.js-item-list').attr('data-OfferTerm1', val.OfferTerm1_parse);        
        tmpobj.find('.js-item-list').attr('data-OfferTerm2', val.OfferTerm2_parse);
        tmpobj.find('.js-item-list').attr('data-OfferTerm3', val.OfferTerm3_parse);
        tmpobj.find('.js-item-list').attr('data-OfferTerm4', val.OfferTerm4_parse);
        tmpobj.find('.js-item-list').attr('data-PartnerSpecifics2', val.PartnerSpecifics2);
        tmpobj.find('.js-item-list').attr('data-typeinout', val.type_inout);
        tmpobj.find('.js-item-list').attr('data-Postcode', val.Postcode);
        tmpobj.find('.js-item-list').attr('data-LargeImageURL', val.LargeImageURL);
        tmpobj.find('.js-item-list').attr('data-web', val.web);
        tmpobj.find('.js-item-list').attr('data-guests', val.guests);
        tmpobj.find('.js-item-list').attr('data-offerReference', val.offerReference);
        tmpobj.find('.js-item-list').attr('data-PartnerOfferRRP', val.PartnerOfferRRP);

        var _show_type = true;
		    var _type = val.type;
        if (_type != 'BOTH'){
            if (_type == 'IN' && val.type_inout == '2'){
                _show_type = false;
            }
            if (_type == 'OUT' && val.type_inout == '1'){
                _show_type = false;
            }
        }

        if (_show_type && (val.type_inout == '1' || val.type_inout == '3') ){
          tmpobj.find('.js-item-list').find('.label-in').removeClass('d-none');
        }else{
          tmpobj.find('.js-item-list').find('.label-in').addClass('d-none');
        }

        if (_show_type && (val.type_inout == '2' || val.type_inout == '3') ){
          tmpobj.find('.js-item-list').find('.label-out').removeClass('d-none');
        }else{
          tmpobj.find('.js-item-list').find('.label-out').addClass('d-none');
        }
        tmpobj.find('.credits').html(val.PartnerOfferRRP);
        if ($('#code_incomplete').val() == 'SLG$nestlefamilyclub'){
                var _add = '';
                if (val.City != ''){
                  _add = val.City;
                }

                if (val.Region != ''){
                  if (_add != ''){
                    _add = _add + ',';
                  }
                  _add = _add + ' ' + val.Region;
                }
          
                if (val.Postcode != ''){
                  if (_add != ''){
                    _add = _add + ',';
                  }
                  _add = _add + ' ' + val.Postcode;
                }
                
                tmpobj.find('.js-item-list').find('.offerterm1').html(val.OfferTerm1_parse2);
                
        }else{  
            if (val.type_inout == '1'){
              tmpobj.find('.js-item-list').find('.type-in').removeClass('d-none');
              tmpobj.find('.js-item-list').find('.type-out').addClass('d-none');
              tmpobj.find('.js-item-list').find('.type-out-offerterms').addClass('d-none');          


              tmpobj.find('.js-item-list').find('.type-in').find('.web').html(val.web);
              tmpobj.find('.js-item-list').find('.type-in').find('.web').attr('href', val.web);

            }else{
              tmpobj.find('.js-item-list').find('.type-out').removeClass('d-none');
              tmpobj.find('.js-item-list').find('.type-in').addClass('d-none');
              tmpobj.find('.js-item-list').find('.type-out-offerterms').removeClass('d-none');          
              var _add = '';
              if ($('body').attr('data-lang') == 'usa'){
                _add = val.Address + '<br>' + val.City + ', ' + val.Region;
                if (val.Postcode != ''){
                  _add = _add + ', ' + val.Postcode;
                }
              }else{
                _add = val.Address + '<br>';
                if (val.Postcode != ''){
                  _add = _add + ' ' + val.Postcode;
                }
                if (val.Postcode != '' && val.City != ''){
                  _add = _add + ' - ';
                }
                if (val.City != ''){
                  _add = _add + ' ' + val.City;
                }
              }
              tmpobj.find('.js-item-list').find('.type-out').find('.address').html(_add);
              tmpobj.find('.js-item-list').find('.type-out').find('.web').html(val.web);
              tmpobj.find('.js-item-list').find('.type-out').find('.web').attr('href', val.web);
              tmpobj.find('.js-item-list').find('.type-out').find('.phone').html(val.Phone);
              tmpobj.find('.js-item-list').find('.type-out-offerterms').find('.offerterm3').html(val.OfferTerm3_parse2);
              tmpobj.find('.js-item-list').find('.type-out-offerterms').find('.offerterm4').html(val.OfferTerm4_parse2);
            }
            tmpobj.find('.js-item-list').find('.offerterm1').html(val.OfferTerm1_parse2);
            tmpobj.find('.js-item-list').find('.offerterm2').html(val.OfferTerm2_parse2);

        }

        tmpobj.find('.js-item-list').find('.img-venue').attr('src', val.LargeImageURL);
        tmpobj.find('.js-item-list').find('.venue-name').html(val.RewardName);//VenueName);
        tmpobj.find('.js-item-list').find('.venue-name2').html(val.VenueName);

        
        tmpobj.find(".js-button-download").attr('data-item', 'item_'+val.experience_id);
        tmpobj.find(".js-button-download").attr('data-evento', 'Select center '+val.VenueName);
        
        tmpobj.removeClass("loadajax-item");
        tmpobj.css('opacity',0);
        tmpobj.delay(i*50).animate({opacity: 1},400);
        tmpobj.find(".card-info").delay(i*50).animate({opacity: 1},400);
        tmpobj.find(".card-info").find(".divselected").delay(i*100).animate({opacity: 1},400);
      });
      $(objName).remove();
      //console.log(markers);

      setTimeout(function(){ 
        
        if ($('#pac-input').val() != '' && $('.cont-slider').attr('data-default') != ''){
          var _dvalue = $('.cont-slider').attr('data-default');
        }else{
          var _dvalue = $('#radius_max').val();
        }  
        $( "#slider" ).slider({
          max: $('#radius_max').val(),
          min: $('#radius_min').val(),
          value: _dvalue,
          step: 5	
        });
        if ($('#pac-input').val() != ''){
          $(".cont-slider").removeClass("disabled");
        }
        initMap();
  
          function initSliderStart(){
            if (!$('#slider .slider-start').length){
              $("#slider").append("<div class='slider-start'></div>")
            }
            if ($('#slider').length){
              setTimeout(function(){
                $('.slider-start').width($('#slider .ui-slider-handle')[0].style.left);
              },25)
            }
          }
          initSliderStart();
          $("#slider").on("slidestop", function(event, ui) {
            searchRadius();
          });
        
          $( "#slider" ).on( "slide", function( event, ui ) {
              var endPos = ui.value;
              $('.label-slider').html(endPos+' '+$('#unit').val());
              $('#radius_selected').val(endPos);
              initSliderStart();
          } );
          
          if ($('#show_optin_map').val() == '1'){
            $('.js-map').trigger('click');
          }

          if ($('#pac-input').val() != ''){

            var geocoder = new google.maps.Geocoder();
            var address = $('#pac-input').val();

            geocoder.geocode( { 'address': address}, function(results, status) {

              if (status == google.maps.GeocoderStatus.OK) {
                var lat_input = results[0].geometry.location.lat();
                var lng_input = results[0].geometry.location.lng();
               
                $('#lat').val(lat_input);
                $('#lng').val(lng_input);
                searchRadius();

              } 
            }); 

            
          }  
        }, 500);
    }  

    //validate register form    
    $('#js-btn-send-voucher').on('click', function() {
      var error = false;
      var form = document.getElementById('code_download');
      var formJS = $('#code_download');
      //formJS.find('.alert .items-invalid').html("");
      var _errors= '';

      var name = document.getElementById('itName');
      var lastname = document.getElementById('itLastname');
      var email = document.getElementById('itEmail');
      var itConf = document.getElementById('itConf');
      var offer_selected = document.getElementById('offer_selected');
      var checkLegal1 = document.getElementById('check-bono-legal1');
      var checkLegal2 = document.getElementById('check-bono-legal2');
      var guest = parseInt($('#guests').val());
      if (guest > 0){
        var name1 = document.getElementById('itName1');
        var lastname1 = document.getElementById('itLastname1');
        var name2 = document.getElementById('itName2');
        var lastname2 = document.getElementById('itLastname2');
        var name3 = document.getElementById('itName3');
        var lastname3 = document.getElementById('itLastname3');
        var name4 = document.getElementById('itName4');
        var lastname4 = document.getElementById('itLastname4');
        var name5 = document.getElementById('itName5');
        var lastname5 = document.getElementById('itLastname5');
      }  
      if (guest > 0 && (name1.value == '' || lastname1.value == '')){
        $('#itName1').prop('required',true);
        $('#itLastname1').prop('required',true);
      }  

      if (guest > 1 && (name2.value == '' || lastname2.value == '')){
        $('#itName2').prop('required',true);
        $('#itLastname2').prop('required',true);
      } 

      if (guest > 2 && (name3.value == '' || lastname3.value == '')){
        $('#itName3').prop('required',true);
        $('#itLastname3').prop('required',true);
      } 

      if (guest > 3 && (name1.value == '' || lastname1.value == '')){
        $('#itName4').prop('required',true);
        $('#itLastname4').prop('required',true);
      } 

      if (guest > 4 && (name1.value == '' || lastname1.value == '')){
        $('#itName5').prop('required',true);
        $('#itLastname5').prop('required',true);
      } 
      
      if (form.checkValidity() === false || $('#itEmail').val() != $('#itConf').val() ) {
          error = true;
          if (!name.validity.valid) _errors += ("<li>" + $('#itName').closest('.form-group').data('error-message') + "</li>");
          if (!lastname.validity.valid) _errors += ("<li>" + $('#itLastname').closest('.form-group').data('error-message') + "</li>");
          if (!email.validity.valid) _errors += ("<li>" + $('#itEmail').closest('.form-group').data('error-message') + "</li>");
          if ($('#itEmail').val() != $('#itConf').val()){
            _errors += ("<li>" + $('#itConf').closest('.form-group').data('error-message') + "</li>");
          }
          if (!checkLegal1.validity.valid) _errors += ("<li>" + $('#check-bono-legal1').closest('.form-group').data('error-message') + "</li>");
          if(checkLegal2 != null){
            if (!checkLegal2.validity.valid) _errors += ("<li>" + $('#check-bono-legal2').closest('.form-group').data('error-message') + "</li>");
          }

          if (guest > 0){
            if (!name1.validity.valid) _errors += ("<li>" + $('#itName1').closest('.form-group').data('error-message') + "</li>");
            if (!lastname1.validity.valid) _errors += ("<li>" + $('#itLastname1').closest('.form-group').data('error-message') + "</li>");
          }
          if (guest > 1){
            if (!name2.validity.valid) _errors += ("<li>" + $('#itName2').closest('.form-group').data('error-message') + "</li>");
            if (!lastname2.validity.valid) _errors += ("<li>" + $('#itLastname2').closest('.form-group').data('error-message') + "</li>");
          }

          if (guest > 2){
            if (!name3.validity.valid) _errors += ("<li>" + $('#itName3').closest('.form-group').data('error-message') + "</li>");
            if (!lastname3.validity.valid) _errors += ("<li>" + $('#itLastname3').closest('.form-group').data('error-message') + "</li>");
          }

          if (guest > 3){
            if (!name4.validity.valid) _errors += ("<li>" + $('#itName4').closest('.form-group').data('error-message') + "</li>");
            if (!lastname4.validity.valid) _errors += ("<li>" + $('#itLastname4').closest('.form-group').data('error-message') + "</li>");
          }

          if (guest > 4){
            if (!name5.validity.valid) _errors += ("<li>" + $('#itName5').closest('.form-group').data('error-message') + "</li>");
            if (!lastname5.validity.valid) _errors += ("<li>" + $('#itLastname5').closest('.form-group').data('error-message') + "</li>");
          }
          
        }
        

      formJS.addClass('was-validated');
      if (!error) {
        $('#js-btn-send-voucher').addClass('loader');

        //formJS.find('.alert').slideUp("fast");
        var datos = formJS.serialize();
        $.ajax({
            method: "POST",
            url: "ajax/ajax-download-voucher.php",
            data: datos
          })
          .done(function(resultAjax) {
            formJS.removeClass('was-validated');
            resultDownloadVoucher(resultAjax);
            $('#js-btn-send-voucher').removeClass('loader');
        });
      } else {
        /*formJS.find('.alert').slideDown(function() {});
        plg.PLG_scrollTo(1, "#section-user", 0, 100);*/
        $('#modal-error .modal-body').find('.errors').html(_errors); 
        $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
        myModalError.show();
      }

    }); 
    /*
    console.log('mixpanel', typeof mixpanel);
    if (typeof mixpanel != "undefined") {
			mixpanel.track("Offers page viewed", {
				"Result count": $(".grid-items .item").length
			})
		}
    */  
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};

function initMap(){
  console.log('initMap');
  icon= {
    url:"images/marker2.png",
    scaledSize: new google.maps.Size(22, 40)
  };
  //console.log(markers);
  var val2;

  infowindow = new google.maps.InfoWindow();
  geocoder = new google.maps.Geocoder();
  
  
  var prim_ln = 0;
  var prim_lt = 0;
  $.each(markers, function(index, val) {
    val2="["+val+"]";
    val2=eval(val2);
    val2 = val2[0];
    if (parseInt(val2.tipo) != 1){
      if (val2.lat!=0 && val2.lng!=0){
        prim_ln = val2.lng;
        prim_lt = val2.lat;
      }
    }
  });
  
  map = new google.maps.Map(document.getElementById('map'), {
    //center: {lat: 40.416775, lng: -3.703790},
    center: {lat: prim_lt, lng: prim_ln},
    zoom: 5,
  });
  
  var mapHasIdled = false;
  google.maps.event.addListener(map, 'idle', function() { 
    if(!mapHasIdled){
      printMarkers();
    }
    mapHasIdled = true; 
  });
  setTimeout(function(){
    map.setZoom(5);
  }, 5000);


  const options = {
    fields: ["formatted_address", "geometry", "name"],
    origin: map.getCenter()				
  };

  const input = document.getElementById("pac-input");

  const autocomplete = new google.maps.places.Autocomplete(input, options);	

  autocomplete.addListener("place_changed", function() {
      const place = autocomplete.getPlace();
      
      if (!place.geometry || !place.geometry.location) {
        // window.alert("No details available for input: '" + place.name + "'");
        //if ($('#pac-input').val() == ""){
          $(".cont-slider").addClass("disabled");
        //}

        return;
      }

      var lat_input = place.geometry.location.lat();
      var lng_input = place.geometry.location.lng();
      // If the place has a geometry, then present it on a map.
      $('#lat').val(lat_input);
      $('#lng').val(lng_input);
      
      searchRadius();
      
  });

}

function printMarkers(){
  oms = new plg2.OverlappingMarkerSpiderfier(map, {
            nearbyDistance: 10, //20 default
            keepSpiderfied:true,
    markersWontMove: true,
    markersWontHide: true,
    basicFormatEvents: false
  });
  oms.addListener('format', function(marker, status) {

    
    let iconURL = 
    (status === plg2.OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED) ? "images/marker2.png" :
    (status === plg2.OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE) ? "images/group1.png":
    (status === plg2.OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE) ? "images/marker1.png":
    (status === plg2.OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIED) ? "images/group1.png":
    null;

    var iconSize = new google.maps.Size(23, 32);
    
    marker.setIcon({
    url: iconURL,
    size: iconSize,
    scaledSize: iconSize  // makes SVG icons work in IE
    });		
    


  });

  var items = [];
  var i=0;
  var val2;
  var markers_view = [];
  bounds = new google.maps.LatLngBounds();
  $.each(markers, function(index, val) {
    val2="["+val+"]";
    val2=eval(val2);
    val2 = val2[0];
    if(val2.tipo!=1){
      if (val2.lat!=0 && val2.lng!=0){
        if (!$('.item[id='+val2.item+']').hasClass('d-none')){
          var latLng = new google.maps.LatLng(val2.lat, val2.lng);
          var marker = new google.maps.Marker({
            position: latLng,
            map: map,
            id: val2.item, 
            icon: icon,
            title: val2.item
          });
          bounds.extend(latLng);
          
          var details = "<strong>"+val2.centro+"</strong><br/>"+val2.titulo+"<br/>"+val2.direccion+"<br/><a class='js-marker' href='javascript:;' onclick='openDownload(\"#"+val2.item+"\")'>"+$('#selecttxt').val()+"</a>";
         
          
          markers_view[val2.item] = marker;

          google.maps.event.addListener(marker, 'spider_click', function(e) {  // 'spider_click', not plain 'click'
            infowindow.setContent(details);
            infowindow.open(map, marker);

            if ($(window).width() < 767){
              plg.PLG_scrollTo(2,$('#'+marker.id),0,20);
            }
            else{	
              plg.PLG_scrollTo(2,$('#'+marker.id),0,-330);
            }	
            $('.js-item-list').removeClass('active');
            $('#'+marker.id).addClass('active');

          });
          oms.addMarker(marker); 





        }
      }	

    }

    i++;
    if (i>=10){
      //return false;
    }
  });	 
  
  
  map.fitBounds(bounds);
  const markerCluster = new markerClusterer.MarkerClusterer({ map, markers_view,minimumClusterSize: 10, imagePath: "images/grouping" });

  map.addListener('dragend', function() { 
      oms.formatMarkers();
  } );
}


function showModalDownload(_itdownload){
  
  var offer_selected = _itdownload.closest('.item').attr('data-offerId');
  var _guests = _itdownload.closest('.item').attr('data-guests');
  
	var _url_image = _itdownload.closest('.item').attr('data-LargeImageURL');
	var _typeinout = _itdownload.closest('.item').attr('data-typeinout');
	var _web = _itdownload.closest('.item').attr('data-web');
	var _postcode = _itdownload.closest('.item').attr('data-Postcode');
  var _center_name = _itdownload.closest('.item').attr('data-VenueName');
  var _exp_name = _itdownload.closest('.item').attr('data-RewardName');
  //mixpanel
  var _credits = _itdownload.closest('.item').find('.credits').html();
  _credits = _credits.match(/\d+/)[0];

  if (_url_image.indexOf('NET@') == -1){
		$('#experience-image').attr('src', _url_image);
		$('#experience-image').show();
	}
	else{
		var _im = _url_image.substring(4);
		$('#experience-image').attr('src', _im);
		$('#experience-image').hide();
	}
	$('#img_voucher').val(_url_image);


/*
if (_url_image.indexOf('NET@') == -1){

      $('#experience-image').attr('src', _url_image);
      setTimeout(function(){ 
        if($('#experience-image')[0].naturalHeight == 0){
          _url_image = '';	
          
          $('#experience-image').attr('src', '');
          $('#experience-image').hide();
        }else{
          $('#experience-image').attr('src', _url_image);
          $('#experience-image').show();
        } 
        $('#img_voucher').val(_url_image);
      }, 100);	


  
  }
  else{
    var _im = _url_image.substring(4);
    $('#imagen-experiencia').attr('src', _im);
    $('#imagen-experiencia').hide();
    $('#img_voucher').val(_url_image);
  }

*/




	$('#guests').val(_guests);
	$('#type_inout').val(_typeinout);	
	$('#website').val(_web);
	$("#postcode").val(_postcode);
  $('#itCenter').val(_center_name);
	
	$('.guest').hide();
	$('.guest_1').hide();
	$('.guests').hide();
	if (parseInt(_guests) > 0){
		if (parseInt(_guests) == 1){
			$('.guest_1').show();
			$('.guests').hide();
		}
	else{
	  $('.guest_1').hide();
		$('.guests').show();
	}
	$('.guest').each(function(index, el) {
		if (parseInt($(this).attr('data-guest')) <= parseInt(_guests)){
			$(this).show();
		}	
	});
	}

  $('#offer_selected').val(offer_selected);

	//$('#modalDownload').show();
  myModal = new bootstrap.Modal(document.getElementById('modalDownload'), {
    keyboard: false
  });
  myModal.show();

  /*
  //MIXPANEL
  if (typeof mixpanel != "undefined") {
    mixpanel.track("Voucher page viewed", {
      "Partner name": _center_name,
      "Experience name": _exp_name,
      "Credits": _credits,
      "Filter used": filterused
    });
  }
  */

}  

function resultDownloadVoucher(_result){
  var result = JSON.parse(_result);
  //$('#modalDownload').hide(); 
  /*var myModal = new bootstrap.Modal(document.getElementById('modalDownload'), {
    keyboard: false
  });*/
  myModal.hide();
  if (result['result'] == 'OK'){
    //$('#modalDownloadOk').show(); 
    myModal2 = new bootstrap.Modal(document.getElementById('modalDownloadOk'), {
      keyboard: false
    });
    myModal2.show(); 
    /*
    //MIXPANEL
    if (typeof mixpanel != "undefined") {
      mixpanel.track("Voucher downloaded");
    }  
    */
  }else{
    if (result['error']['msg_error'] == '' || result['error']['msg_error'] == null){
      plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
    }
    else{
      plg.PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
    }
  } 
  
  // UPDATE TOTAL CREDITS
  // console.log('html', $('.js-user_credits').html());
  // console.log('credits', $('.js-user_credits').html());

  $('.js-user_credits').html(result['credits']);
}

function openDownload__(_it){
  console.log('itt: '+_it);
  var _elem = _it.find('.js-button-download');
  //$(\"#"+val2.item+"\").find(\".js-button-download\").trigger(\"click\")
console.log(_elem.html());
  //showModalDownload(_elem);
}

window.openDownload = function (_it){
  console.log('itt: '+_it);
  var _elem = $(_it).find('.js-button-download');
  //$(\"#"+val2.item+"\").find(\".js-button-download\").trigger(\"click\")
  showModalDownload(_elem);
}


function searchRadius(){
  
  if ($('#pac-input').val() != ''){
    $('.grid-items').fadeOut();
    



    $('#txt-results').hide();
    $('#load').show();
    var _datos = $('#fRadius').serialize();
    $.ajax({
      url: "ajax/ajax-radius-offers.php",
      type: "POST",
      dataType: "json",
      data: _datos,
      success: function (response) {            
          //console.log(response);
          if (response.result == 'OK'){

            if (response.results > 0 ){
              $('#txt-results span').html($('#results1').val()+response.results+$('#results2').val());
            }
            else{
              $('#txt-results span').html($('#noresults').val());
            }	
            
            var _offers = response.offers;
            

            //reordenarItems();
            $('.js-offer').hide();
            $('.js-offer').removeClass('d-flex');
            $('.js-item-list').closest('.js-offer').addClass('d-none'); //para que funcione el mapa

            $.each(_offers, function(index, value) {
                $('.js-item-list[data-experienceid="'+parseInt(value.id)+'"]').closest('.js-offer').show(); 
                $('.js-item-list[data-experienceid="'+parseInt(value.id)+'"]').closest('.js-offer').addClass('d-flex'); 
                if ($('#show_distance').val() == '1'){
                  $('.js-item-list[data-experienceid="'+parseInt(value.id)+'"]').find('.distance').html(value.distance);
                }
                $('.js-item-list[data-experienceid="'+parseInt(value.id)+'"]').closest('.js-offer').removeClass('d-none'); 
            }); 
            
            
  
            $('.grid-items').fadeIn();
            $('#txt-results').fadeIn();
            $('#load').fadeOut();
            initMap();
          }else{
            plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
            $('.grid-items').fadeOut();
            $('#txt-results').fadeIn();
            $('#load').fadeOut();
          }  
      },
      error: function (xhr, status) {
        plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
        $('.grid-items').fadeOut();
        $('#txt-results').fadeOut();
        $('#load').fadeOut();
      },
      complete: function (xhr, status) {
      }
    });
  }
  else{
    plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
    $('#row-listado').show();
    $('#txt-results').show();
    $('#load').fadeOut();
  }	
}

function filterOffers(){
    var _valA = $('.js-activities').val();
    var _valC = parseInt($('.js-credits').val());
    var _valR = $('.js-regions').val();

    $('.js-item-list').closest('.js-offer').removeClass('d-none');
    
    if (_valA != ''){
      $('.js-item-list[data-Activity!="'+_valA+'"]').closest('.js-offer').addClass('d-none');
    }	
    if (_valR != ''){
      $('.js-item-list[data-RegionSearch!="'+_valR+'"]').closest('.js-offer').addClass('d-none');
    }
    
    var _mins = [0, 10, 30, 60];
    var _maxs = [9, 29, 59, 9999]
    var _ind = _valC - 1;
    if (parseInt(_valC) != 0){

      $('.js-item-list').each(function(index, el) {
        if (!$(this).closest('.js-offer').hasClass('d-none')){
          var _credit = parseInt($(this).attr('data-PartnerOfferRRP'));
          if (_credit < _mins[_ind] || _credit > _maxs[_ind]){
            $(this).closest('.js-offer').addClass('d-none');
          }
        }	
      });
    }
    

    if ($('.js-list').hasClass('active')){
      $('#map').hide();
    }else{
      initMap();
      $('#map').show();
    }	

    

    var html_experiencias = $('#total_centers2').html();
    if ($(".js-item-list:visible").length <= 1){
      html_experiencias = $('#total_centers2_sing').html();
    }
    if ($(".js-item-list:visible").length == 0){
      $('#txt-results').html($('#no_centers').html());

    }else{
      $('#txt-results').html($('#total_centers1').html() + $(".js-item-list:visible").length + html_experiencias);
    }
}