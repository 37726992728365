var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");
const bootstrap = require('bootstrap');
var myModal;
var myModalError;
export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init remember');
    myModalError = new bootstrap.Modal(document.getElementById('modal-error'), {}); 

    $('#js-btremember').on('click', function() {
        var error = false;
        var form = document.getElementById('formRemember');
        var formJS = $('#formRemember');
        //formJS.find('.alert .items-invalid').html("");
        var _errors= '';
        var email = document.getElementById('email');
        if (form.checkValidity() === false) {
          error = true;
          if (!email.validity.valid) _errors += ("<li>" + $('#email').closest('.form-group').data('error-message') + "</li>");
        }
        formJS.addClass('was-validated');
        if (!error) {
          $('#js-btremember').addClass('loader');
  
          formJS.find('.alert').slideUp("fast");
          var datos = formJS.serialize();
          $.ajax({
              method: "POST",
              url: "ajax/ajax-remember.php",
              data: datos
            })
            .done(function(resultAjax) {
            formJS.removeClass('was-validated');
            resultRemember(resultAjax);
            $('#js-btremember').removeClass('loader');
          });
        } else {
          $('#modal-error .modal-body').find('.errors').html(_errors); 
          $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
          myModalError.show();
        }
  
      });
  
      $('#js-btreset').on('click', function() {
        var error = false;
        var form = document.getElementById('formReset');
        var formJS = $('#formReset');
        //formJS.find('.alert .items-invalid').html("");
        var _errors= '';
        var password = document.getElementById('itPassword');
        var password2 = document.getElementById('itPassword2');
        if (form.checkValidity() === false || $('#itPassword').val() != $('#itPassword2').val()) {
            error = true;
            if (!password.validity.valid) _errors += ("<li>" + $('#itPassword').closest('.form-group').data('error-message') + "</li>");
            if ($('#itPassword').val() != $('#itPassword2').val()){
              _errors += ("<li>" + $('#itPassword2').closest('.form-group').data('error-message') + "</li>");
           }
        }
        formJS.addClass('was-validated');
        if (!error) {
            
          $('#js-btreset').addClass('loader');
  
          formJS.find('.alert').slideUp("fast");
          var datos = formJS.serialize();
          $.ajax({
              method: "POST",
              url: "ajax/ajax-reset.php",
              data: datos
            })
            .done(function(resultAjax) {
            formJS.removeClass('was-validated');
            resultReset(resultAjax);
            $('#js-btreset').removeClass('loader');
          });
        } else {
          $('#modal-error .modal-body').find('.errors').html(_errors); 
          $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
          myModalError.show();
        }
  
      });
     
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};

function resultRemember(_result){
    var result = JSON.parse(_result);
    $('.error_noexists').hide();
    if (result['result'] == 'OK' ){
        document.location.href="remember-confirm";
    }else{
        $('.btn').removeClass("loader");
        $('.error_noexists').show();
    }  
  }

function resultReset(_result){
    var result = JSON.parse(_result);
    if (result['result'] == 'OK' ){
        document.location.href = 'remember-end';
    }else{
        $('#js-btreset').removeClass("loader");

        $('#modal-error .modal-body').find('.errors').hide(); 
        $('#modal-error .modal-body').find('.txterrors').html(result['error']);
        $('#modal-error .modal-button').find('.txterrors').html('<a href="javascript:;" class="js-close">'+result['boton']+"</a>");
        $('#modal-error .modal-button').show();
        myModalError.show();
    }
}