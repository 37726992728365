var $ = require( "jquery" );
var jQuery = $;
import "./lib/functions-comunes.js";
import "./lib/functions-initlisteners.js";
import "./lib/functions-forms.js";


import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';


/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
  home
});

$(document).ready(() => routes.loadEvents());
window.app=routes;


