var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");
const bootstrap = require('bootstrap');
var myModal;
export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init partner');
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};

