
var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");
const plgforms = require("../lib/functions-forms");
import home from '../routes/home';
window.homeJS=home;
import categories from '../routes/categories';
window.categoriesJS=categories;
import subcategories from '../routes/subcategories';
window.subcategoriesJS=subcategories;
import offers from '../routes/offers';
window.offersJS=offers;
import contact from '../routes/contact';
window.contactJS=contact;
import partner from '../routes/partner';
window.partnerJS=partner;
import remember from '../routes/remember';
window.rememberJS=remember;
import area from '../routes/area';
window.areaJS=area;
import common from '../routes/common';
window.commonJS=common;

$('.js-cerrar').click(function(e) {
  $('#menu_desplegable button').trigger('click');
});

/**
 * LISTENER animated menu anchor 
 */
$(".js-menu-ancla").click(function(e) {
  e.preventDefault();
  var ancla = $(this).attr("href");
  var offset = $(this).data("menuancla-offset");
  if (offset == "" || offset == undefined) {
    offset = 0;
  }
  plg.PLG_scrollTo(1, ancla, 0, offset, 0);
});

/**
 * LISTENER show alert modal
 */
$(".js-modal-alerta").click(function(e) {
  e.preventDefault();
  plg.PLG_showModalAlerta(
    $(this).data("alerta-titulo"),
    $(this).data("alerta-texto")
  );
});

/**
 * LISTENER ajax loader 
 */
$("*[data-loadajax]").each(function() {
  var loadajaxfile = $(this).attr('data-callbackfile');
  var loadajax = $(this).attr('data-loadajax');
  var loadajaxasync = $(this).attr('data-loadajaxasync');

  var _data = '';
  switch (loadajax) { 
  }  

  $.ajax({
    url: "/ajax/ajax-"+loadajax+".php",
    type: "POST",
    dataType: "json",
    data: _data,
    async:loadajaxasync,
    success: function (response) {
      setTimeout(function(){
        window.[loadajaxfile+"JS"].callback_ajax(loadajax,JSON.stringify(response));
      },200)
    },
    error: function (xhr, status) {
    },
    complete: function (xhr, status) {
    }
  });  


});




