import Swiper, { Navigation,Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
Swiper.use([Navigation,Autoplay]);
import * as bootstrap from 'bootstrap';
var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");
var myModal;
var myModalError;

export default {
  init() {
    console.log("init home.");
    myModalError = new bootstrap.Modal(document.getElementById('modal-error'), {}); 

    //validate register form    
    $('#js-btregister').on('click', function() {
      var error = false;
      var form = document.getElementById('formRegister');
      var formJS = $('#formRegister');
      //formJS.find('.alert .items-invalid').html("");
      var _errors= '';
      var name = document.getElementById('itName');
      var lastname = document.getElementById('itLastName');
      var email = document.getElementById('itEmail');
      var nif = document.getElementById('nif');

      var selProvincia = document.getElementById('selProvincia');
      var itFecha = document.getElementById('itFecha');
      var itImporteCOVAP = document.getElementById('itImporteCOVAP');
      var itHora = document.getElementById('itHora');
      //var ean = document.getElementById('ean');
      
      var checkLegal1 = document.getElementById('check-legal1');
      var checkLegal2 = document.getElementById('check-legal2');
      if (form.checkValidity() === false || !validarDNI($('#nif').val()) ) {
        error = true;
        if (!name.validity.valid) _errors += ("<li>" + $('#itName').closest('.form-group').data('error-message') + "</li>");
        if (!lastname.validity.valid) _errors += ("<li>" + $('#itLastName').closest('.form-group').data('error-message') + "</li>");

        if (!email.validity.valid) _errors += ("<li>" + $('#itEmail').closest('.form-group').data('error-message') + "</li>");
        if ($('#itEmail').val() != $('#confEmail').val()){
           _errors += ("<li>" + $('#confEmail').closest('.form-group').data('error-message') + "</li>");
        }
        
        if (!nif.validity.valid || !validarDNI($('#nif').val())) {
          _errors += ("<li>" + $('#nif').closest('.form-group').data('error-message') + "</li>");
          $('#nif').toggleClass('invalid', true).toggleClass('valid', false);
        }else{
          $('#nif').toggleClass('invalid', false).toggleClass('valid', true);
        }  

        /*if (ean.validity.valid || !validateEAN(ean)){
            _errors += ("<li>" + $('#ean').closest('.form-group').data('error-message') + "</li>");
            $('#ean').toggleClass('invalid', true).toggleClass('valid', false);
        }*/

        if (!selProvincia.validity.valid) _errors += ("<li>" + $('#selProvincia').closest('.form-group').data('error-message') + "</li>");
        if (!itFecha.validity.valid) _errors += ("<li>" + $('#itFecha').closest('.form-group').data('error-message') + "</li>");
        if (!itImporteCOVAP.validity.valid) _errors += ("<li>" + $('#itImporteCOVAP').closest('.form-group').data('error-message') + "</li>");
        if (!itHora.validity.valid) _errors += ("<li>" + $('#itHora').closest('.form-group').data('error-message') + "</li>");

        if (!checkLegal1.validity.valid) _errors += ("<li>" + $('#check-legal1').closest('.form-group').data('error-message') + "</li>");
        if (!checkLegal2.validity.valid) _errors += ("<li>" + $('#check-legal2').closest('.form-group').data('error-message') + "</li>");
        
      }

      //formJS.addClass('was-validated');



      var archivoInput = document.getElementById('ficfoto1');
      var archivo = archivoInput.files[0];
      //console.log('archivo::'+archivo);
      var hay_archivo = true;
      if (!archivo || archivo === undefined || archivo == 'undefined') {
        $('#div-ficfoto1').attr('data-error-message', 'Debe subir su ticket de compra.')
        _errors += ("<li>" + $('#div-ficfoto1').attr('data-error-message') + "</li>");
        error = true;
        hay_archivo = false;
      }
      
      if (hay_archivo){
        // Verificar el formato del archivo
        var formatoValido = /\.(jpeg|jpg|png|pdf)$/i.test(archivo.name);
        if (!formatoValido) {
          $('#div-ficfoto1').attr('data-error-message', 'El fichero debe tener el formato JPEG, JPG, PNG ou PDF.')
          _errors += ("<li>" + $('#div-ficfoto1').attr('data-error-message') + "</li>");
          error = true;
        }
      
        // Verificar el tamaño del archivo
        var maxSize = 10 * 1024 * 1024; // 10MB
        if (archivo.size > maxSize) {
          $('#div-ficfoto1').attr('data-error-message', 'El fichero no debe pesar más de 10 MB.')
          _errors += ("<li>" + $('#div-ficfoto1').attr('data-error-message') + "</li>");
          error = true;
        }
      }  

      if (!error) {
        $('#js-btregister').addClass('loader');

        //formJS.find('.alert').slideUp("fast");
        //var datos = formJS.serialize();
        var form = $('#formRegister')[0];
        var formData = new FormData(form);
        formData.append('ficfoto1', archivo); 
        formJS.removeClass('was-validated');

        $.ajax({
            method: "POST",
            url: "ajax/ajax-register.php",
            data: formData,
            processData: false,
            contentType: false,
          })
          .done(function(resultAjax) {

            formJS.removeClass('was-validated');
            resultRegister(resultAjax);
            $('#js-btregister').removeClass('loader');
        });
      } else {

        formJS.addClass('was-validated');
        $('#modal-error .modal-body').find('.errors').html(_errors); 
        $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
        myModalError.show();

      }

    });   
    

    $('.js-uploader').click(function(event) {
      /* Act on the event */
      var _nom_input = $(this).data('fichero');
       $('#'+_nom_input).trigger('click');
    });

    $('.fichero').on('change', function(){
       var idfichero = $(this).attr('id');

       var _fich = $("[data-fichero='"+idfichero+"']" ).attr('id');
       $('#'+_fich).addClass('ticket-ok');
       var _nom_tmp = $(this).val().split("\\");
       var _nom = _nom_tmp[_nom_tmp.length-1];
      // 
       var fileSize = this.files[0].size;
       var fileName = this.files[0].name;
       _ext = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
       $(this).closest('.form-group').attr('data-tipo-error', '0');//no hay error
       if (fileSize > 10000000){//error tamaño
         $(this).closest('.form-group').attr('data-tipo-error', '2');
         $(this).closest('.form-group').addClass('has-error');
         $(this).closest('.form-group').removeClass('has-sucess');
       }

       var _ext = _nom.slice((_nom.lastIndexOf(".") - 1 >>> 0) + 2);
       var validos = ['png', 'jpg', 'pdf', 'jpeg', 'PNG', 'JPG', 'JPEG', 'PDF', 'webp', 'WEBP'];
       if ($.inArray(_ext, validos) < 0){//error extensión
         $(this).closest('.form-group').attr('data-tipo-error', '3');
         $(this).closest('.form-group').addClass('has-error');
         $(this).closest('.form-group').removeClass('has-sucess');
       } 



       var _target = $(this).attr('data-targetFichero');
       //alert(_target);
       $('#'+_target).val(_nom);
       $('.nombre-archivo span').html(_nom);
       $(".fa-check-ok").css("opacity", "1");

    });
   
    


/**/
/*
$("body").on('dragenter', function(ev) {
  $(".btn-file").addClass("highlightDropArea");
});
*/
// $("body").on('dragleave', function(ev) {
//   $(".btn-file").removeClass("highlightDropArea");
//   console.log('OUT');
// });
/*
var inputFile = document.getElementById('ficfoto1');
$("body").on('drop', function(ev) {
  // Dropping files
  ev.preventDefault();
  ev.stopPropagation();

  // iterate through files and upload it on server.
  if(ev.originalEvent.dataTransfer){
    if(ev.originalEvent.dataTransfer.files.length) {
        var droppedFiles = ev.originalEvent.dataTransfer.files;
        var file = droppedFiles;

        // let formData = new FormData()
        // formData.append('ficfoto1', file)


        // let list = new DataTransfer();
        // let file2 = new File(['content'], file[0].name);
        // list.items.add(file2);
        // let myFileList = list.files;
        // $('#ficfoto1').prop('files',myFileList)

      //move image "file" to "ficfoto1" input field
        var reader = new FileReader();
        reader.onload = function(e) { 
            $('#ficFoto1').attr('src', e.target.result);
        }
        reader.readAsDataURL(file[0]);
        inputFile.files = file;

        $('.fichero').trigger('change'); 
        console.log($("#ficfoto1").prop("files")[0]);
      }
  }

  $(".btn-file").removeClass("highlightDropArea");
  return false;
});

$("body").on('dragover', function(ev) {
  ev.preventDefault();
});
*/
/**/




        var inputFecha = document.getElementById('itFecha');
        if (inputFecha) {
          inputFecha.addEventListener('change', function() {
              if (!isValidDate(this.value)) {
                  this.value = '';
                  alert('Por favor, seleccione la fecha utilizando el calendario.');
              }
          });

          inputFecha.addEventListener('keydown', function(event) {
              event.preventDefault();
          });

          inputFecha.addEventListener('contextmenu', function(event) {
              event.preventDefault();
          });
        }  
        function isValidDate(dateString) {
            var regEx = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateString.match(regEx)) return false;  // Formato no válido
            var d = new Date(dateString);
            var dNum = d.getTime();
            if (!dNum && dNum !== 0) return false; // Fecha inválida (por ejemplo, fecha no válida como 'aaaammdd')
            return d.toISOString().slice(0, 10) === dateString;
        }




  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};



function resultRegister(_result){
  var result = JSON.parse(_result);
  if (result['result'] == 'OK' ){
    document.location.href="gracias.php";
  }else{
    $('#js-btregister').removeClass("loader");

    $('#modal-error .modal-body').find('.errors').hide(); 
    $('#modal-error .modal-body').find('.txterrors').html(result['error']);
    $('#modal-error .modal-button').find('.txterrors').html('<a href="javascript:;" class="js-close">'+result['boton']+"</a>");
    $('#modal-error .modal-button').show();
    myModalError.show();
  }
}  

function resultComprobar(_result){
  var result = JSON.parse(_result);
  if (result['result'] == 'OK' ){
    document.location.href="gracias.php";
  }else{
    $('#bt-comprobar').removeClass("loader");

    $('#modal-error .modal-body').find('.errors').hide(); 
    $('#modal-error .modal-body').find('.txterrors').html(result['error']);
    $('#modal-error .modal-button').find('.txterrors').html('<a href="javascript:;" class="js-close">'+result['boton']+"</a>");
    $('#modal-error .modal-button').show();
    myModalError.show();
  }
}  

function validarDNI(valor) {

  if(valor.length<9) {
    return false;
  }
 
  valor = valor.toUpperCase();

  var letra = valor.substring(valor.length - 1);
  var numero = valor.substring(0, 8);

  if (numero.indexOf("X") >= 0){
    numero = numero.replace("X", 0);
  }
  if (numero.indexOf("Y") >= 0){
    numero = numero.replace("Y", 1);
  }
  if (numero.indexOf("Z") >= 0){
    numero = numero.replace("Z", 2);
  }
 
  // Si es un NIE hay que cambiar la primera letra por 0, 1 ó 2 dependiendo de si es X, Y o Z.
 
  var modulo = numero % 23;
  var letras_validas = "TRWAGMYFPDXBNJZSQVHLCKE";
  var letra_correcta = letras_validas.substring(modulo, modulo+1);   //substr($letras_validas, $modulo, 1);
  //console.log('modulo = '+modulo);
  //console.log('letra = '+letra+', letra valida = '+letra_correcta+', numero = '+numero);
  if(letra_correcta!=letra) {
    return false;
  }else {
    return true;
  }


//var exr = /6\d{8}/;
//return exr.test(dni);
//return ( validar_nif(valor) || validar_nie(valor,7) || validar_nie(valor,8) );
}

$('.solo-letras').on('keypress', function(event) {
  /* Act on the event */
  return soloLetras(event);
});
$('.letras-numeros').on('keypress', function(event) {
  /* Act on the event */
  return soloLetrasNumeros(event);
});
$('.solo-numeros').on('keypress', function(event) {
  /* Act on the event */
  return soloNumeros(event);
});
function soloLetrasNumeros(e){
  var key = e.keyCode || e.which;
  var tecla = String.fromCharCode(key).toLowerCase();
  var letras = "áéíóúabcdefghijklmnñopqrstuvwxyz0123456789";
  var especiales = "8-37-39-46";
  var tecla_especial = false;
  for(var i in especiales){
       if(key == especiales[i]){
           tecla_especial = true;
           break;
       }
   }

   if(letras.indexOf(tecla)==-1 && !tecla_especial){
       return false;
   }
}
function soloLetras(e){
  var key = e.keyCode || e.which;
  var tecla = String.fromCharCode(key).toLowerCase();
  var letras = " áéíóúabcdefghijklmnñopqrstuvwxyz-_";
  var especiales = "8-37-39-46";
  var tecla_especial = false;
  for(var i in especiales){
          if(key == especiales[i]){
              tecla_especial = true;
              break;
          }
      }

      if(letras.indexOf(tecla)==-1 && !tecla_especial){
          return false;
      }
  }
function soloNumeros(e){
  var key = e.keyCode || e.which;
  var tecla = String.fromCharCode(key).toLowerCase();
  var letras = "0123456789.";
  var especiales = "8-37-39-46";

  var tecla_especial = false
     for(var i in especiales){
          if(key == especiales[i]){
              tecla_especial = true;
              break;
          }
      }

      if(letras.indexOf(tecla)==-1 && !tecla_especial){
          return false;
      }
  }

  
  function calcularDigitoControlFechaYYMMDDOLD(fechaYYMMDD) {
      // Pesos para cada dígito de la fecha YYMMDD
      var pesos = [7, 3, 1, 7, 3, 1];
      var suma = 0;
  
      // Calcular la suma de productos
      for (var i = 0; i < 6; i++) {
          suma += parseInt(fechaYYMMDD.charAt(i)) * pesos[i];
      }
  
      // Calcular el módulo 10
      var modulo = suma % 10;
  
      // Calcular el dígito de control
      var digitoControl = modulo === 0 ? 10 : modulo;
  
      return digitoControl;
  }

  function validarNIF2(numeroCompleto) {
    // Validar que el número completo tenga exactamente 14 caracteres
    if (numeroCompleto.length !== 14) {
        return false;
    }

    // Extraer el primer bloque de fecha en formato YYMMDD y su dígito de control
    var fecha1YYMMDD = numeroCompleto.substring(0, 7);
    var digitoControl1 = parseInt(numeroCompleto.charAt(6));

    // Extraer el segundo bloque de fecha en formato YYMMDD y su dígito de control
    var fecha2YYMMDD = numeroCompleto.substring(7, 14);
    var digitoControl2 = parseInt(numeroCompleto.charAt(13));

    // Calcular el dígito de control esperado para el primer bloque de fecha YYMMDD
    var digitoControlCalculado1 = calcularDigitoControlFechaYYMMDD(fecha1YYMMDD);

    // Calcular el dígito de control esperado para el segundo bloque de fecha YYMMDD
    var digitoControlCalculado2 = calcularDigitoControlFechaYYMMDD(fecha2YYMMDD);

    // Comparar los dígitos de control proporcionados con los calculados
    var valido1 = digitoControl1 === digitoControlCalculado1;
    var valido2 = digitoControl2 === digitoControlCalculado2;

    // Devolver true si ambos bloques son válidos
    return valido1 && valido2;
}

function calcularDigitoControlFechaYYMMDD1(fechaYYMMDD) {
    // Pesos para cada dígito de la fecha YYMMDD
    var pesos = [7, 3, 1, 7, 3, 1];
    var suma = 0;

    // Calcular la suma de productos
    for (var i = 0; i < 6; i++) {
        suma += parseInt(fechaYYMMDD.charAt(i)) * pesos[i];
    }

    // Calcular el módulo 10
    var modulo = suma % 10;

    // Calcular el dígito de control
    var digitoControl = modulo === 0 ? 10 : modulo;

    return digitoControl;
}

function calcularDigitoControlFechaYYMMDD(fechaYYMMDD) {
  // Pesos para cada dígito de la fecha YYMMDD
  var pesos = [7, 3, 1, 7, 3, 1];
  var suma = 0;

  // Calcular la suma de productos
  for (var i = 0; i < 6; i++) {
      suma += parseInt(fechaYYMMDD.charAt(i)) * pesos[i];
  }

  // Calcular el módulo 10
  var modulo = suma % 10;

  // El dígito de control es el módulo
  return modulo;
}

function validateEAN(eanInput) {
  const validValues = [
    "8411585027016",
    "8411585021526",
    "8411585021502",
    "8411585026347"
  ];

  const eanValue = eanInput.value;

  if (validValues.includes(eanValue)) {
      return true;
  } else {
      return false;
  }
}