var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");

export default {
  init() {
    // JavaScript to be fired on the home page
    var categories_selected = [];
    $('.js-type-network').on('click', function(){
      categories_selected = [];
      plg.PLG_setCookie("tlcpromo_in", 0, 365);
      plg.PLG_setCookie("tlcpromo_out", 0, 365);
      
      if ($(this).attr('data-type') == 'IN'){
        if (!$(this).hasClass('selected')){
          $('.js-category').slideUp('slow');
          $('.js-category[data-in="1"]').slideDown('slow');
          $('.js-type-network[data-type="OUT"]').parent().removeClass('selected');
          $('.js-type-network[data-type="IN"]').parent().addClass('selected');
          $('.js-type-network[data-type="ALL"]').parent().removeClass('selected');
          plg.PLG_setCookie("tlcpromo_in", 1, 365);
          plg.PLG_setCookie("tlcpromo_out", 0, 365);
        }else{
          $('.js-category').slideDown('slow');
          plg.PLG_setCookie("tlcpromo_in", 1, 365);
          plg.PLG_setCookie("tlcpromo_out", 1, 365);
          $('.js-type-network[data-type="IN"]').parent().removeClass('selected');
          $('.js-type-network[data-type="ALL"]').parent().removeClass('selected');
        }
      }else{
        if ($(this).attr('data-type') == 'OUT'){
          if (!$(this).hasClass('selected')){
            $('.js-category').slideUp('slow');
            $('.js-category[data-out="1"]').slideDown('slow');
            $('.js-type-network[data-type="OUT"]').parent().addClass('selected');
            $('.js-type-network[data-type="IN"]').parent().removeClass('selected');
            $('.js-type-network[data-type="ALL"]').parent().removeClass('selected');
            plg.PLG_setCookie("tlcpromo_out", 1, 365);
            plg.PLG_setCookie("tlcpromo_in", 0, 365);
          }else{
            $('.js-category').slideDown('slow');
            plg.PLG_setCookie("tlcpromo_in", 1, 365);
            plg.PLG_setCookie("tlcpromo_out", 1, 365);
            $('.js-type-network[data-type="OUT"]').parent().removeClass('selected');
            $('.js-type-network[data-type="ALL"]').parent().removeClass('selected');
          }  
        }else{
          $('.js-category').slideDown('slow');
          plg.PLG_setCookie("tlcpromo_in", 1, 365);
          plg.PLG_setCookie("tlcpromo_out", 1, 365);
          $('.js-type-network[data-type="OUT"]').parent().removeClass('selected');
          $('.js-type-network[data-type="IN"]').parent().removeClass('selected');
          $('.js-type-network[data-type="ALL"]').parent().addClass('selected');
        }
      }
    });

    $('.js-category').on('click', function(){
      if (jQuery.inArray( $(this).attr('data-network'), categories_selected ) < 0){        
        categories_selected.push($(this).attr('data-network'));
        $(this).addClass('selected');
      }else{
        var ind = jQuery.inArray( $(this).attr('data-network'), categories_selected );
        categories_selected.splice( $.inArray(ind,categories_selected) ,1 );
        $(this).removeClass('selected');
      }
      //console.log(categories_selected);
    });

    $('#js-btsearch').on('click', function(){
      
          var _catsel = categories_selected.join(',');
          if (_catsel == ''){
            plg.PLG_showModalAlerta($('#txtups').val(), $(this).attr('data-error'));
          }else{
            plg.PLG_setCookie("tlcpromo_categories", _catsel+',', 365);
            document.location.href = "subcategories";
          }
          /*
          if (typeof mixpanel != "undefined") {
            mixpanel.track("Categories selected", {
              "Categories": categories_selected
            });
          }
          */

          
      
    });
  },
  callback_ajax(funcion,response){
    eval("this.callback_"+funcion+"("+response+")");
  },
  callback_sectioncategories(res){
    var _resultcb = res;
    if (_resultcb['result'] == 'OK' ){
      var objName='#section_categories .loadajax-item';
      $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
      var _elem = $(objName);
      jQuery.each( _resultcb['data'], function( i, val ) {
        var tmpobj=_elem.clone(true).appendTo(_elem.parent());      
        
        tmpobj.find(".imagen").css("background-image","url('"+val.image+"')");
        tmpobj.find(".capa").find("img").attr('src', val.image_network);
        tmpobj.find(".capa").find("img").attr('alt', val.title);
        tmpobj.find(".capa").css('background-color', val.bgcolor);
        tmpobj.attr('data-network', val.category_id);
        tmpobj.attr('data-in', val.in);
        tmpobj.attr('data-out', val.out);
        tmpobj.find(".text-hover").html(val.description);
  
        tmpobj.removeClass("loadajax-item");
        tmpobj.css('opacity',0);
        tmpobj.delay(i*50).animate({opacity: 1},400);
        
      });
      $('#js-btsearch').delay(1000).animate({opacity: 1},400);
      $(objName).remove();
    }  
    /*
    //MIXPANEL
    if (typeof mixpanel != "undefined") {
      mixpanel.track("Category Selection Viewed");
    }
    */
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
